import React from "react";
import styled from 'styled-components';

import StatueImg from '../../../static/images/DeusaDoDireitoX2.png';

import media from '../utils/media'

export const Container = styled.div`

    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    width: 100%;
    flex:1;



 


    ${media.sm`
        &:before {
            display:none;
        }
        content : "";
        height: 100vh;
        display: flex;
        margin-left: 2em;

  

    `}
    
    ${media.md`
       
            
            background-image: url(${StatueImg});
            background-repeat: no-repeat;
            background-size: auto calc(100vh + 500px);
            background-position-x: 350px;        
            background-position-y: -300px;
    `}
    ${media.lg`
        background-size: auto 125vh ;
        background-position-x: 455px;        
        background-position-y: 0px;
          
    `}

    ${media.xlg`
          
    `}
`;

export const Description = styled.div`
    width:84.3vw;
    background-color : rgba(255,255,255,.5);
    padding: 3vw 1vh; 
    font-size: 90%;
    P{
        color :${(props)=>props.theme.colors.primary};
        width:100%;
        
        max-height : 65vh;
        overflow : hidden;
    }

    h1 {
        
 
        display :none;
    }

${media.sm`
    display: flex;
    flex-direction: column;
    background-color : unset;

    justify-content: center;   
    h1 {
        display :block;
        max-width : 40rem;
        overflow :hidden;
    }
    P{
        color :${(props)=>props.theme.colors.textP};
        width:48.0rem;
        max-width : 48rem;
        max-height : 28rem; 
    }

`}

`;

export const Image = styled.div`

`;

