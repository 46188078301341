import React from 'react';
import {graphql, PageProps} from 'gatsby';
import SEO from '../components/SEO';

import { H1 ,P} from '../styles/Fonts';
import { Container, Description, Image } from '../styles/pages/home-page.style';
import {Logo } from '../components/Layout';
export interface PageHomeProps {
    markdownRemark :{
        frontmatter : {
        description : string;
        title : string;
        }
    }
}

export interface HomeBaseProps{
    description : string;
    title : string;
}

interface HomeProps extends HomeBaseProps{
    description : string;
    title : string;
    location:{pathname : string;}
    preview? : boolean;
}
export  function PageHomeTemplate({ title ,description,location,preview  } : HomeProps){

return (<>
       {!preview?(<SEO title={title} description={description} pathname={location.pathname} />) :''}
		<Container>
			<Description>
				<H1  size={"S3"} color={"primary"}>{title}</H1>
                <P  size={"S3"} color={"textTitle"} > {description}</P>
			</Description>
            <Logo />
		</Container>
    </>);}

const  PageHome = ({data, location} :PageProps<PageHomeProps>)=>{
    const content = data.markdownRemark.frontmatter;

 return(
     <PageHomeTemplate
     title={content.title}
     description={content.description}
     location ={location}
     />
 );
 }
export default PageHome;

export const query = graphql`
    query HomePageQuery {
    markdownRemark(frontmatter: {templateKey: {eq: "home-page"}}) {
        frontmatter {
        description
        title
        }
    }
    }
`;